import React from "react"
import PartnerCarousel from "../../components/PartnerCarousel"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../../components/Layout"
import { Container, Row, Col } from "react-bootstrap"
import PeaceOfMindGuarantee from "../../components/PeaceOfMindGuarantee"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChalkboardTeacher,
  faLaptop,
  faWifi,
  faLock,
  faTools,
  faStar,
} from "@fortawesome/free-solid-svg-icons"
import Services from "../../components/Services"
import EducationOffers from "../../components/education/EducationOffers"
import SEO from "../../components/seo"
import SectionTitle from "../../components/SectionTitle"
import IconBoxList from "../../components/BoxList"

export default function Laptops() {
  const data = useStaticQuery(graphql`
    query educationLaptops {
      laptopsAndTablets: file(
        relativePath: { eq: "services/AdobeStock_274709086.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      storage: file(
        relativePath: { eq: "services/AdobeStock_101536916.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      Software: file(
        relativePath: { eq: "services/AdobeStock_317550082.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      Maintenance: file(
        relativePath: { eq: "services/AdobeStock_308707407.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      ChargingSolutions: file(relativePath: { eq: "services/LapCabby.jpg" }) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
    }
  `)

  const services = [
    {
      title: "Laptops & Tablets",
      description: "iMacs, Macbooks, Google Chromebooks and PCs",
      image: data.laptopsAndTablets.childImageSharp.fixed,
    },
    {
      title: "Storage",
      description: "Backup, NAS, Airport Time Capsule",
      image: data.storage.childImageSharp.fixed,
    },
    {
      title: "Charging Solutions",
      description:
        "Charging Trolley and cabinets, Charge & Sync Trolley, Charging Flight Case",
      image: data.ChargingSolutions.childImageSharp.fixed,
    },
    {
      title: "Software",
      description:
        "Mobile Device Management, Microsoft, Operating Systems, Adobe, User Monitoring",
      image: data.Software.childImageSharp.fixed,
    },
    {
      title: "Maintenance",
      description:
        "Extended Warranties, Telephone Support, Remote Support, Onsite Support, Installation",
      image: data.Maintenance.childImageSharp.fixed,
    },
  ]

  return (
    <Layout headerType="education">
      <SEO title="Compulease · Laptops Education" />
      <EducationOffers />
      <Container>
        <Row>
          <Col>
            <SectionTitle
              subtitle="Keeping your school in touch with the latest technology as
                    it develops"
              title="Laptops and iPad Leasing"
              description={
                <>
                  <p>
                    These days, portable technology is as powerful as desktop
                    technology. Add to that the versatility & flexibility it
                    provides to education and you can teach IT...anytime,
                    anyplace and anywhere.
                  </p>
                  <p>
                    Compulease™ provides a whole range of education leasing
                    portable solutions from all the leading manufacturers, so
                    whether your requirement is for a single laptop for a
                    teacher, multiple laptops or tablets for the students to
                    use, or a complete fully installed mobile classroom, we have
                    the solution for you.
                  </p>
                </>
              }
            />
            <IconBoxList
              boxSizeLarge
              items={[
                {
                  title: "Mobile classrooms",
                  desc:
                    "A mobile classroom uses laptops or tablets to combine the management and control of a computer suite, the freedom of portable computing and the power of the internet into a simple, affordable rolling solution",
                  icon: (
                    <FontAwesomeIcon icon={faChalkboardTeacher} color="white" />
                  ),
                },
                {
                  title: "Jump-start learning anywhere",
                  desc:
                    "Your student’s access to technology is no longer limited to a single computer lab or classroom. With no wires, no retrofitting and no installation required, the mobile classroom can turn any room, corner of the building or even the school field into a powerful learning centre",
                  icon: <FontAwesomeIcon icon={faWifi} color="white" />,
                },
                {
                  title: "Laptops provide total flexibility",
                  desc:
                    "And because the laptops are on a mobile cart, they can be shared by teachers and students in different classrooms to maximise use",
                  icon: <FontAwesomeIcon icon={faLaptop} color="white" />,
                },
                {
                  title: "Security",
                  desc:
                    "Security is not an issue either – the solid construction of the trolley and the locking doors ensure that the laptops will be perfectly safe when they are not in use",
                  icon: <FontAwesomeIcon icon={faLock} color="white" />,
                },
                {
                  title: "No wires, no retrofitting required",
                  desc:
                    "Best of all, a wireless mobile classroom reduces wiring costs and installation time. You don’t need to tear down walls and reconfigure buildings. You don’t need to invest in a dedicated computer lab. You have a cost effective solution for placing multiple devices in a classroom",
                  icon: <FontAwesomeIcon icon={faTools} color="white" />,
                },
                {
                  title: "Freedom of Choice",
                  desc:
                    "Due to our long term relationships with all the leading brands you can be sure that you will receive a guaranteed “value for money” solution",
                  icon: <FontAwesomeIcon icon={faStar} />,
                },
              ]}
            />
            <PeaceOfMindGuarantee />
            <PartnerCarousel type="education" />
          </Col>
        </Row>
        <Row>
          <Col>
            <Services title="Solutions we provide" services={services} />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}
